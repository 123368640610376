import MobileLogo from "../components/Internal/MobileLogo";
import Header from "../components/Header";
import { NextIntlClientProvider } from "next-intl";
import messages from "../messages/en.json";

const NotFound = () => {
  return (
    <>
      <MobileLogo />
      <Header />
      <div className="h-screen text-center flex flex-col justify-center items-center">
        <h2>This page could not be found.</h2>
      </div>
    </>
  );
};
export default function Page404() {
  return (
    <>
      <NextIntlClientProvider messages={messages} locale="en">
        <NotFound />
      </NextIntlClientProvider>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {},
    revalidate: true,
  };
}
